import { useMemo } from 'react';
import { Waypoint } from 'react-waypoint';

import {
  type DtoBrand,
  type DtoMultiBrandResponse,
} from '@lp-lib/api-service-client/public';

import { useListLoader } from '../../hooks/useListLoader';
import { useSetQueryParam } from '../../hooks/useQueryParam';
import { apiService, type Paginator } from '../../services/api-service';
import { ErrorMessage } from '../Game/GameCenter';
import { Loading } from '../Loading';
import { BrandCard, BrandMenu } from './BrandCard';

export function BrandGridLayout(props: {
  gridTemplateColumns: number;
  children: React.ReactNode;
}) {
  const { gridTemplateColumns, children } = props;

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${gridTemplateColumns}, minmax(0, 1fr))`,
      }}
      className='w-full grid gap-2.5'
    >
      {children}
    </div>
  );
}

export interface BrandListProps {
  gridTemplateColumns: number;
  editable?: boolean;
  pageSize?: number;
  onClick?: (brand: DtoBrand) => void;
}

function BrandListBase(
  props: BrandListProps & {
    paginator: Paginator<DtoMultiBrandResponse, DtoBrand>;
  }
) {
  const { paginator, gridTemplateColumns, editable } = props;

  const { items, state, error, dao, handleRetry, handleLoadMore } =
    useListLoader(paginator, (a, b) => a.id === b.id);
  const setQueryParam = useSetQueryParam();

  const handleClick = (brand: DtoBrand) => {
    if (props.onClick) {
      props.onClick(brand);
    } else {
      setQueryParam('brandId', brand.id);
    }
  };

  const handleDuplicate = (brand: DtoBrand) => {
    dao.addItem(brand);
  };

  const handleDelete = (brand: DtoBrand) => {
    dao.deleteItem(brand);
  };

  const showEmptyMessage =
    !paginator.hasMore() && items.length === 0 && !state.isRunning;
  const showErrorMessage = !!error;
  const canLoad = state.isStarted && !state.isRunning && !error;

  return (
    <div className='w-full'>
      <BrandGridLayout gridTemplateColumns={gridTemplateColumns}>
        {items.map((brand) => (
          <BrandCard
            key={brand.id}
            brand={brand}
            onClick={() => handleClick(brand)}
            menu={
              editable ? (
                <BrandMenu
                  brand={brand}
                  onDuplicate={handleDuplicate}
                  onDelete={() => handleDelete(brand)}
                />
              ) : null
            }
          />
        ))}
      </BrandGridLayout>
      {state.isRunning && (
        <div className='w-full flex items-center justify-center text-white my-8'>
          <Loading />
        </div>
      )}
      {showErrorMessage && (
        <div className='w-full flex items-center justify-center text-white my-8'>
          <ErrorMessage text='Something went wrong' handleRetry={handleRetry} />
        </div>
      )}
      {showEmptyMessage && (
        <div className='w-full flex items-center justify-center text-white text-xl my-8'>
          <ErrorMessage text={`No brands`} />
        </div>
      )}
      {canLoad && (
        <Waypoint onEnter={handleLoadMore} fireOnRapidScroll>
          <div>&nbsp;</div>
        </Waypoint>
      )}
    </div>
  );
}

export function TaggedBrandList(props: BrandListProps & { tagId: number }) {
  const paginator = useMemo(
    () => apiService.brand.getByTagId(props.tagId, props.pageSize),
    [props.pageSize, props.tagId]
  );
  return <BrandListBase {...props} paginator={paginator} />;
}

export function UntaggedBrandList(props: BrandListProps) {
  const paginator = useMemo(
    () => apiService.brand.getUntagged(props.pageSize),
    [props.pageSize]
  );
  return <BrandListBase {...props} paginator={paginator} />;
}

export function SearchBrandList(
  props: BrandListProps & {
    query: string;
  }
) {
  const paginator = useMemo(
    () => apiService.brand.search(props.query, props.pageSize),
    [props.pageSize, props.query]
  );
  return <BrandListBase {...props} paginator={paginator} />;
}
