import { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import useSWR from 'swr';

import {
  type DtoBrand,
  EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { useSetQueryParam } from '../../hooks/useQueryParam';
import { apiService } from '../../services/api-service';
import { type Tag, TaggedObjectType, TagUtils, virtualTags } from '../../types';
import { Emitter } from '../../utils/emitter';
import { ErrorMessage } from '../Game/GameCenter';
import { DoubleRightArrow } from '../icons/Arrows';
import { Loading } from '../Loading';
import { useFeaturedTags } from '../Tagging';
import { BrandCard, BrandMenu } from './BrandCard';
import { BrandGridLayout, type BrandListProps } from './BrandList';

type BrandDiscoveryEvents = {
  brandChanged: (brand: DtoBrand) => void;
};

function TaggedBrandsPreview(
  props: {
    tag: Tag;
    emitter: Emitter<BrandDiscoveryEvents>;
  } & BrandListProps
) {
  const { tag, emitter } = props;

  const untagged = tag.id === virtualTags.untagged.id;
  const [entered, setEntered] = useState(false);
  const setQueryParam = useSetQueryParam();

  const { data, mutate, isLoading, error } = useSWR(
    entered ? ['/tagged-brands-preview', tag.id] : null,
    async () => {
      const paginator = untagged
        ? apiService.brand.getUntagged(props.pageSize)
        : apiService.brand.getByTagId(tag.id, props.pageSize);
      const brands = (await paginator.next()).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      return {
        brands,
        hasMore: paginator.hasMore(),
      };
    }
  );

  useEffect(() => {
    if (!entered) return;

    return emitter.on('brandChanged', (brand) => {
      if (untagged) {
        if (!brand.tags || brand.tags.length === 0) mutate();
        return;
      }

      if (brand.tags?.find((t) => t.id === tag.id)) {
        mutate();
      }
    });
  }, [emitter, entered, mutate, tag.id, untagged]);

  return (
    <Waypoint onEnter={() => setEntered(true)} fireOnRapidScroll>
      {untagged && !data?.brands.length ? null : (
        <div className='w-full flex flex-col'>
          <header className='text-2xl font-medium mb-5'>
            {untagged
              ? tag.name
              : `${tag.name} (${TagUtils.getObjectsCount(
                  tag,
                  TaggedObjectType.Brand
                )})`}
          </header>

          {data && data.brands.length > 0 && (
            <BrandGridLayout gridTemplateColumns={props.gridTemplateColumns}>
              {data.brands.map((brand) => (
                <BrandCard
                  key={brand.id}
                  brand={brand}
                  onClick={() => setQueryParam('brandId', brand.id)}
                  menu={
                    props.editable ? (
                      <BrandMenu
                        brand={brand}
                        onDuplicate={(brand) =>
                          emitter.emit('brandChanged', brand)
                        }
                        onDelete={() => emitter.emit('brandChanged', brand)}
                      />
                    ) : null
                  }
                />
              ))}
            </BrandGridLayout>
          )}
          {data && data.brands.length === 0 && (
            <div className='flex justify-center'>
              <ErrorMessage text={`No brands`} />
            </div>
          )}
          {isLoading && <Loading />}
          {error && (
            <div className='flex justify-center'>
              <ErrorMessage
                text='Something went wrong'
                handleRetry={() => mutate()}
              />
            </div>
          )}
          {data && data.hasMore && (
            <button
              type='button'
              onClick={() =>
                untagged
                  ? setQueryParam('untagged', 'true')
                  : setQueryParam('tag', tag.slug)
              }
              className='btn mt-4 self-end flex items-center gap-3 text-white font-medium tracking-wider leading-none'
            >
              View All <DoubleRightArrow />
            </button>
          )}
        </div>
      )}
    </Waypoint>
  );
}

export function BrandDiscovery(props: BrandListProps): JSX.Element {
  const emitter = useInstance(() => new Emitter<BrandDiscoveryEvents>());
  const { data: tags, isLoading } = useFeaturedTags(
    EnumsPageName.PageNameBrands,
    true
  );

  if (isLoading) return <Loading />;
  return (
    <div className='flex flex-col gap-8'>
      {tags?.map((t) => (
        <TaggedBrandsPreview key={t.id} emitter={emitter} tag={t} {...props} />
      ))}
    </div>
  );
}
